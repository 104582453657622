import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ITotalProduct } from '../interfaces/total-product';
import { IFramingSystem } from '../interfaces/framing-system';
import { IGlazingSystem } from '../interfaces/glazing-system';
import { IUser } from '../interfaces/user';

export interface ITotalProductDynamicControlsParameters {
    formGroup?: string;
    framingSystems?: IFramingSystem[];
    glazingSystem1s?: IGlazingSystem[];
    glazingSystem2s?: IGlazingSystem[];
    users?: IUser[];
}

export class TotalProductDynamicControls {

    formGroup: string;
    framingSystems?: IFramingSystem[];
    glazingSystem1s?: IGlazingSystem[];
    glazingSystem2s?: IGlazingSystem[];
    users?: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private totalproduct?: ITotalProduct, additionalParameters?: ITotalProductDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'TotalProduct';
        this.framingSystems = additionalParameters && additionalParameters.framingSystems || undefined;
        this.glazingSystem1s = additionalParameters && additionalParameters.glazingSystem1s || undefined;
        this.glazingSystem2s = additionalParameters && additionalParameters.glazingSystem2s || undefined;
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            CondensationResistance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Condensation Resistance',
                name: 'CondensationResistance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.CondensationResistance || null,
            }),
            FramingSystemId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Framing System',
                name: 'FramingSystemId',
                options: this.framingSystems,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.FramingSystemId || null,
            }),
            GlazingSystem1Id: new DynamicField({
                formGroup: this.formGroup,
                label: 'Glazing System1',
                name: 'GlazingSystem1Id',
                options: this.glazingSystem1s,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.GlazingSystem1Id || null,
            }),
            GlazingSystem2Id: new DynamicField({
                formGroup: this.formGroup,
                label: 'Glazing System2',
                name: 'GlazingSystem2Id',
                options: this.glazingSystem2s,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.totalproduct && this.totalproduct.GlazingSystem2Id || null,
            }),
            Shgc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shgc',
                name: 'Shgc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.Shgc || null,
            }),
            UFactor: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Factor',
                name: 'UFactor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.UFactor || null,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.UserId || null,
            }),
            VisibleTransmittance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Transmittance',
                name: 'VisibleTransmittance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.totalproduct && this.totalproduct.VisibleTransmittance || null,
            }),
        };

        this.View = {
            CondensationResistance: new DynamicLabel({
                label: 'Condensation Resistance',
                value: this.totalproduct && this.totalproduct.CondensationResistance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            FramingSystemId: new DynamicLabel({
                label: 'Framing System',
                value: getMetaItemValue(this.framingSystems as unknown as IMetaItem[], this.totalproduct && this.totalproduct.hasOwnProperty('FramingSystemId') ? this.totalproduct.FramingSystemId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            GlazingSystem1Id: new DynamicLabel({
                label: 'Glazing System1',
                value: getMetaItemValue(this.glazingSystem1s as unknown as IMetaItem[], this.totalproduct && this.totalproduct.hasOwnProperty('GlazingSystem1Id') ? this.totalproduct.GlazingSystem1Id : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            GlazingSystem2Id: new DynamicLabel({
                label: 'Glazing System2',
                value: getMetaItemValue(this.glazingSystem2s as unknown as IMetaItem[], this.totalproduct && this.totalproduct.hasOwnProperty('GlazingSystem2Id') ? this.totalproduct.GlazingSystem2Id : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Shgc: new DynamicLabel({
                label: 'Shgc',
                value: this.totalproduct && this.totalproduct.Shgc || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UFactor: new DynamicLabel({
                label: 'U Factor',
                value: this.totalproduct && this.totalproduct.UFactor || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.totalproduct && this.totalproduct.hasOwnProperty('UserId') ? this.totalproduct.UserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            VisibleTransmittance: new DynamicLabel({
                label: 'Visible Transmittance',
                value: this.totalproduct && this.totalproduct.VisibleTransmittance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
        };

    }
}
