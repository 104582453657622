import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IFrame } from '../interfaces/frame';
import { IFrameType } from '../interfaces/frame-type';

export interface IFrameDynamicControlsParameters {
    formGroup?: string;
    frameTypes?: IFrameType[];
}

export class FrameDynamicControls {

    formGroup: string;
    frameTypes?: IFrameType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private frame?: IFrame, additionalParameters?: IFrameDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Frame';
        this.frameTypes = additionalParameters && additionalParameters.frameTypes || undefined;

        this.Form = {
            FrameTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame Type',
                name: 'FrameTypeId',
                options: this.frameTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.frame && this.frame.FrameTypeId || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.frame && this.frame.hasOwnProperty('Name') && this.frame.Name != null ? this.frame.Name.toString() : '',
            }),
            Pfd: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pfd',
                name: 'Pfd',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.frame && this.frame.Pfd || null,
            }),
            Shgc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shgc',
                name: 'Shgc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.frame && this.frame.Shgc || null,
            }),
            UEdge: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Edge',
                name: 'UEdge',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.frame && this.frame.UEdge || null,
            }),
            UValue: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Value',
                name: 'UValue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.frame && this.frame.UValue || null,
            }),
        };

        this.View = {
            FrameTypeId: new DynamicLabel({
                label: 'Frame Type',
                value: getMetaItemValue(this.frameTypes as unknown as IMetaItem[], this.frame && this.frame.hasOwnProperty('FrameTypeId') ? this.frame.FrameTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.frame && this.frame.hasOwnProperty('Name') && this.frame.Name != null ? this.frame.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Pfd: new DynamicLabel({
                label: 'Pfd',
                value: this.frame && this.frame.Pfd || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            Shgc: new DynamicLabel({
                label: 'Shgc',
                value: this.frame && this.frame.Shgc || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            UEdge: new DynamicLabel({
                label: 'U Edge',
                value: this.frame && this.frame.UEdge || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            UValue: new DynamicLabel({
                label: 'U Value',
                value: this.frame && this.frame.UValue || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
        };

    }
}
