import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IGlass } from '../interfaces/glass';
import { IInterlayerType } from '../interfaces/interlayer-type';
import { ISilkScreenColor } from '../interfaces/silk-screen-color';
import { ISilkScreenPattern } from '../interfaces/silk-screen-pattern';
import { IVendor } from '../interfaces/vendor';

export interface IGlassDynamicControlsParameters {
    formGroup?: string;
    vendors?: IVendor[];
    interlayerTypes?: IInterlayerType[];
    silkScreenColors?: ISilkScreenColor[];
    silkScreenPatterns?: ISilkScreenPattern[];
}

export class GlassDynamicControls {

    formGroup: string;
    vendors?: IVendor[];
    interlayerTypes?: IInterlayerType[];
    silkScreenColors?: ISilkScreenColor[];
    silkScreenPatterns?: ISilkScreenPattern[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private glass?: IGlass, additionalParameters?: IGlassDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Glass';
        this.vendors = additionalParameters && additionalParameters.vendors || undefined;
        this.interlayerTypes = additionalParameters && additionalParameters.interlayerTypes || undefined;
        this.silkScreenColors = additionalParameters && additionalParameters.silkScreenColors || undefined;
        this.silkScreenPatterns = additionalParameters && additionalParameters.silkScreenPatterns || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('Archived') && this.glass.Archived != null ? this.glass.Archived : false,
            }),
            CanBeUsedForDoublePaneAsInboardLayer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Double Pane As Inboard Layer',
                name: 'CanBeUsedForDoublePaneAsInboardLayer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForDoublePaneAsInboardLayer') && this.glass.CanBeUsedForDoublePaneAsInboardLayer != null ? this.glass.CanBeUsedForDoublePaneAsInboardLayer : false,
            }),
            CanBeUsedForDoublePaneAsOutboardLayer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Double Pane As Outboard Layer',
                name: 'CanBeUsedForDoublePaneAsOutboardLayer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForDoublePaneAsOutboardLayer') && this.glass.CanBeUsedForDoublePaneAsOutboardLayer != null ? this.glass.CanBeUsedForDoublePaneAsOutboardLayer : false,
            }),
            CanBeUsedForMonolithic: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Monolithic',
                name: 'CanBeUsedForMonolithic',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForMonolithic') && this.glass.CanBeUsedForMonolithic != null ? this.glass.CanBeUsedForMonolithic : false,
            }),
            CanBeUsedForTriplePaneAsInboardLayer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Triple Pane As Inboard Layer',
                name: 'CanBeUsedForTriplePaneAsInboardLayer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsInboardLayer') && this.glass.CanBeUsedForTriplePaneAsInboardLayer != null ? this.glass.CanBeUsedForTriplePaneAsInboardLayer : false,
            }),
            CanBeUsedForTriplePaneAsMiddleLayer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Triple Pane As Middle Layer',
                name: 'CanBeUsedForTriplePaneAsMiddleLayer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsMiddleLayer') && this.glass.CanBeUsedForTriplePaneAsMiddleLayer != null ? this.glass.CanBeUsedForTriplePaneAsMiddleLayer : false,
            }),
            CanBeUsedForTriplePaneAsOutboardLayer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can Be Used For Triple Pane As Outboard Layer',
                name: 'CanBeUsedForTriplePaneAsOutboardLayer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsOutboardLayer') && this.glass.CanBeUsedForTriplePaneAsOutboardLayer != null ? this.glass.CanBeUsedForTriplePaneAsOutboardLayer : false,
            }),
            ColorB: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color B',
                name: 'ColorB',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.ColorB || null,
            }),
            ColorG: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color G',
                name: 'ColorG',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.ColorG || null,
            }),
            ColorR: new DynamicField({
                formGroup: this.formGroup,
                label: 'Color R',
                name: 'ColorR',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.ColorR || null,
            }),
            IgdbId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Igdb',
                name: 'IgdbId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glass && this.glass.IgdbId || null,
            }),
            InterlayerThickness: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interlayer Thickness',
                name: 'InterlayerThickness',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.InterlayerThickness || null,
            }),
            InterlayerTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interlayer Type',
                name: 'InterlayerTypeId',
                options: this.interlayerTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.InterlayerTypeId || null,
            }),
            Laminated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Laminated',
                name: 'Laminated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glass && this.glass.hasOwnProperty('Laminated') && this.glass.Laminated != null ? this.glass.Laminated : false,
            }),
            LeedPoints: new DynamicField({
                formGroup: this.formGroup,
                label: 'Leed Points',
                name: 'LeedPoints',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.LeedPoints || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(200) ],
                validators: { 'required': true, 'maxlength': 200 },
                value: this.glass && this.glass.hasOwnProperty('Name') && this.glass.Name != null ? this.glass.Name.toString() : '',
            }),
            NeedsGlazingSystemReCalc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Needs Glazing System Re Calc',
                name: 'NeedsGlazingSystemReCalc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.hasOwnProperty('NeedsGlazingSystemReCalc') && this.glass.NeedsGlazingSystemReCalc != null ? this.glass.NeedsGlazingSystemReCalc : false,
            }),
            OpticsFile: new DynamicField({
                formGroup: this.formGroup,
                label: 'Optics File',
                name: 'OpticsFile',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.glass && this.glass.hasOwnProperty('OpticsFile') && this.glass.OpticsFile != null ? this.glass.OpticsFile.toString() : '',
            }),
            PublishDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Publish Date',
                name: 'PublishDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.PublishDate || null,
            }),
            SilkScreenColorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Silk Screen Color',
                name: 'SilkScreenColorId',
                options: this.silkScreenColors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.SilkScreenColorId || null,
            }),
            SilkScreenPatternId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Silk Screen Pattern',
                name: 'SilkScreenPatternId',
                options: this.silkScreenPatterns,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.SilkScreenPatternId || null,
            }),
            Thickness: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thickness',
                name: 'Thickness',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.Thickness || null,
            }),
            VendorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vendor',
                name: 'VendorId',
                options: this.vendors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glass && this.glass.VendorId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.glass && this.glass.hasOwnProperty('Archived') && this.glass.Archived != null ? this.glass.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForDoublePaneAsInboardLayer: new DynamicLabel({
                label: 'Can Be Used For Double Pane As Inboard Layer',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForDoublePaneAsInboardLayer') && this.glass.CanBeUsedForDoublePaneAsInboardLayer != null ? this.glass.CanBeUsedForDoublePaneAsInboardLayer : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForDoublePaneAsOutboardLayer: new DynamicLabel({
                label: 'Can Be Used For Double Pane As Outboard Layer',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForDoublePaneAsOutboardLayer') && this.glass.CanBeUsedForDoublePaneAsOutboardLayer != null ? this.glass.CanBeUsedForDoublePaneAsOutboardLayer : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForMonolithic: new DynamicLabel({
                label: 'Can Be Used For Monolithic',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForMonolithic') && this.glass.CanBeUsedForMonolithic != null ? this.glass.CanBeUsedForMonolithic : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForTriplePaneAsInboardLayer: new DynamicLabel({
                label: 'Can Be Used For Triple Pane As Inboard Layer',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsInboardLayer') && this.glass.CanBeUsedForTriplePaneAsInboardLayer != null ? this.glass.CanBeUsedForTriplePaneAsInboardLayer : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForTriplePaneAsMiddleLayer: new DynamicLabel({
                label: 'Can Be Used For Triple Pane As Middle Layer',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsMiddleLayer') && this.glass.CanBeUsedForTriplePaneAsMiddleLayer != null ? this.glass.CanBeUsedForTriplePaneAsMiddleLayer : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CanBeUsedForTriplePaneAsOutboardLayer: new DynamicLabel({
                label: 'Can Be Used For Triple Pane As Outboard Layer',
                value: this.glass && this.glass.hasOwnProperty('CanBeUsedForTriplePaneAsOutboardLayer') && this.glass.CanBeUsedForTriplePaneAsOutboardLayer != null ? this.glass.CanBeUsedForTriplePaneAsOutboardLayer : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ColorB: new DynamicLabel({
                label: 'Color B',
                value: this.glass && this.glass.ColorB || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            ColorG: new DynamicLabel({
                label: 'Color G',
                value: this.glass && this.glass.ColorG || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            ColorR: new DynamicLabel({
                label: 'Color R',
                value: this.glass && this.glass.ColorR || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            IgdbId: new DynamicLabel({
                label: 'Igdb',
                value: this.glass && this.glass.IgdbId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            InterlayerThickness: new DynamicLabel({
                label: 'Interlayer Thickness',
                value: this.glass && this.glass.InterlayerThickness || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            InterlayerTypeId: new DynamicLabel({
                label: 'Interlayer Type',
                value: getMetaItemValue(this.interlayerTypes as unknown as IMetaItem[], this.glass && this.glass.hasOwnProperty('InterlayerTypeId') ? this.glass.InterlayerTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Laminated: new DynamicLabel({
                label: 'Laminated',
                value: this.glass && this.glass.hasOwnProperty('Laminated') && this.glass.Laminated != null ? this.glass.Laminated : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LeedPoints: new DynamicLabel({
                label: 'Leed Points',
                value: this.glass && this.glass.LeedPoints || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.glass && this.glass.hasOwnProperty('Name') && this.glass.Name != null ? this.glass.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedsGlazingSystemReCalc: new DynamicLabel({
                label: 'Needs Glazing System Re Calc',
                value: this.glass && this.glass.hasOwnProperty('NeedsGlazingSystemReCalc') && this.glass.NeedsGlazingSystemReCalc != null ? this.glass.NeedsGlazingSystemReCalc : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            OpticsFile: new DynamicLabel({
                label: 'Optics File',
                value: this.glass && this.glass.hasOwnProperty('OpticsFile') && this.glass.OpticsFile != null ? this.glass.OpticsFile.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PublishDate: new DynamicLabel({
                label: 'Publish Date',
                value: this.glass && this.glass.PublishDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            SilkScreenColorId: new DynamicLabel({
                label: 'Silk Screen Color',
                value: getMetaItemValue(this.silkScreenColors as unknown as IMetaItem[], this.glass && this.glass.hasOwnProperty('SilkScreenColorId') ? this.glass.SilkScreenColorId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SilkScreenPatternId: new DynamicLabel({
                label: 'Silk Screen Pattern',
                value: getMetaItemValue(this.silkScreenPatterns as unknown as IMetaItem[], this.glass && this.glass.hasOwnProperty('SilkScreenPatternId') ? this.glass.SilkScreenPatternId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Thickness: new DynamicLabel({
                label: 'Thickness',
                value: this.glass && this.glass.Thickness || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            VendorId: new DynamicLabel({
                label: 'Vendor',
                value: getMetaItemValue(this.vendors as unknown as IMetaItem[], this.glass && this.glass.hasOwnProperty('VendorId') ? this.glass.VendorId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
