import { IEntity } from './base';

import { IGlazingSystem } from './glazing-system';
import { IProject } from './project';
import { IUser } from './user';
import { IWindowFrame } from './window-frame';

export interface ILeedReport extends IEntity {
    UserId: number;
    GlazingSystemId?: number;
    WindowFrameId?: number;
    ProjectId: number;
    DateCreated: Date;

    // foreign keys
    GlazingSystem?: IGlazingSystem;
    Project?: IProject;
    User?: IUser;
    WindowFrame?: IWindowFrame;
}
