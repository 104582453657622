import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { GlazingSystemService, ProjectService, LeedReportService } from '@system-select/web-services';
import { IGlass, IGlazingSystem, IProject, IWindowFrame, IDocument, ILeedReport } from '@system-select/model';
import { LeedReportBuilderInterfaceStateService } from '../services/leed-report-builder-interface-state.service';
import { FileService, IGlazingSystemLayer } from '@system-select/common';
import { ProductDocumentService } from '@system-select/web-services';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'leed-report-complete',
    styles: [`
        .report-actions {
            margin-top: 20px;
        }
    `],
    templateUrl: 'leed-report-complete.component.html',
})

export class LeedReportCompleteComponent implements OnInit {
    loginPopupModalApi: IModalWrapperApi;
    createAccountModalApi: IModalWrapperApi;
    closeModalOptions: IModalOptions = {
        allowEscapeKey: true,
        allowOutsideClick: true,
        showCloseButton: true,
    };
    isAuthenticated: boolean;
    currentUserId: number;
    isSaved = false;
    glazingSystem: IGlazingSystem;
    glass: IGlass;
    layers: IGlazingSystemLayer[];
    windowFrame: IWindowFrame;
    project: IProject;
    leedReport: ILeedReport;
    loadingResult = false;

    constructor(
        private stateService: LeedReportBuilderInterfaceStateService,
        private leedReportService: LeedReportService,
        private authService: AuthService,
        private projectService: ProjectService,
        private glazingSystemService: GlazingSystemService,
        private notificationsService: NotificationsService,
        private productDocumentService: ProductDocumentService,
    ) {
    }

    ngOnInit(): void {
        this.authService.isAuthenticated().subscribe((isAuthed) => {
            this.isAuthenticated = isAuthed;
            if (isAuthed) {
                this.currentUserId = this.authService.currentUser.getValue().Id;
            }
        });
        this.glazingSystem = this.stateService.glazingSystem$.getValue() || null;
        this.windowFrame = this.stateService.windowFrame$.getValue() || null;
        this.project = this.stateService.project$.getValue() || null;
        this.leedReport = {
            Id: 0,
            UserId: 0,
            Project: this.project,
            WindowFrame: this.windowFrame,
            GlazingSystem: this.glazingSystem,
            ProjectId: null,
            DateCreated: null,
        };
    }

    onSave(): void {
        if (this.isAuthenticated) {
            const project = this.stateService.project$.getValue();
            if (!project.Id) {
                // this means they logged in during the process so
                // we still have to create this project
                this.loadingResult = true;
                project.UserId = this.currentUserId;
                this.projectService.create(project).subscribe((projectId) => {
                    this.project = project;
                    this.verifyGlazingSystem(projectId);
                });
            } else {
                this.verifyGlazingSystem(project.Id);
            }
        } else {
            setTimeout(() => {
                this.loginPopupModalApi.show();
            }, 0);
        }
    }

    updateAuthenticationStatus(isLoggedIn: boolean): void {
        this.loginPopupModalApi.close();
        this.createAccountModalApi.close();
        this.isAuthenticated = isLoggedIn;
        this.currentUserId = this.authService.currentUser.getValue().Id;
        this.project.UserId = this.currentUserId;
        this.projectService.create(this.project).subscribe((projectId) => {
            this.verifyGlazingSystem(projectId);
        });
    }

    private verifyGlazingSystem(projectId: number): void {
        this.loadingResult = true;
        if (this.glazingSystem) {
            // Make a copy so we can keep the nav properties on the UI
            const glazingSystem: IGlazingSystem = JSON.parse(JSON.stringify(this.glazingSystem));
            glazingSystem.GlazingSystemGasLayers.forEach((item) => {
                item.Thickness = Math.round(item.Thickness * 100000) / 100000; // 5 decimal points
                item.GasType = null;
            });
            glazingSystem.GlazingSystemSolidLayers.forEach((item) => item.Glass = null);
            this.glazingSystemService.createWithFks(glazingSystem).subscribe((gsId) => {
                const glazingSystemId = gsId;
                this.createLeedReport(projectId, glazingSystemId);
            });
        } else {
            this.createLeedReport(projectId, null);
        }
    }

    private createLeedReport(projectId: number, glazingSystemId: number = null): void {
        const windowFrameId = this.windowFrame ? this.windowFrame.Id : null;
        this.leedReportService.create({
            GlazingSystemId: glazingSystemId,
            Id: 0,
            ProjectId: projectId,
            UserId: this.currentUserId,
            WindowFrameId: windowFrameId,
        } as ILeedReport).subscribe((id) => {
            this.success(id);
        });
    }

    success(leedReportId: number): void {
        this.loadingResult = false;
        this.notificationsService.success('LEED Report saved successfully');
        this.leedReport.Id = leedReportId;
        this.isSaved = true;
    }

    createAccountSelected(isCreatingAccount: boolean): void {
        if (isCreatingAccount) {
            this.loginPopupModalApi.close();
            setTimeout(() => {
                this.createAccountModalApi.show();
            }, 0);
        }
    }

    onBuildAnotherReportSelected(): void {
        this.stateService.reset();
    }

    downloadLeedReportDocuments(): void {
        this.loadingResult = true;
        const windowFrameDocs = this.windowFrame ? this.windowFrame.Documents : [];
        // Flattens an array of Document arrays (IDocument[][]) then groups the Documents to avoid downloading duplicate docs
        const glassDocs: IDocument[] = this.glazingSystem ? this.glazingSystem.GlazingSystemSolidLayers
            .map((gs) => gs.Glass.Documents)
            .reduce((acc, d) => [...acc, ...d], []) : [];
        const groupedDocs = windowFrameDocs.concat(glassDocs).reduce((acc, value) => {
            if (!acc[value.Id]) {
                acc[value.Id] = [];
            }
            acc[value.Id].push(value);
            return acc;
        }, {});
        const uniqueDocs: IDocument[] = [];
        for (const key in groupedDocs) {
            if (Object.prototype.hasOwnProperty.call(groupedDocs, key)) {
                const element = groupedDocs[key] as IDocument[];
                uniqueDocs.push(element[0]);
            }
        }

        if (uniqueDocs.length === 0) {
            this.leedReportService.getLeedReportSheet(this.leedReport.Id)
                .pipe(finalize(() => this.loadingResult = false))
                .subscribe((pdf) => {
                    FileService.save(pdf, 'LEED Report.pdf', 'application/pdf', true);
                });
        } else {
            // the parts of this LEED have documents so we have to zip everything
            this.leedReportService.getLeedReportDocumentsZipped(this.leedReport.Id)
                .pipe(finalize(() => this.loadingResult = false))
                .subscribe((zip) => {
                    FileService.save(zip as unknown as string, 'LEED Report.zip', 'application/zip', false);
                });
        }
    }
}
