import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { ICustomerSource } from '../interfaces/customer-source';
import { ICustomerStatus } from '../interfaces/customer-status';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    statuses?: ICustomerStatus[];
    sources?: ICustomerSource[];
}

export class CustomerDynamicControls {

    formGroup: string;
    statuses?: ICustomerStatus[];
    sources?: ICustomerSource[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Customer';
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.sources = additionalParameters && additionalParameters.sources || undefined;

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name != null ? this.customer.Name.toString() : '',
            }),
            SourceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Source',
                name: 'SourceId',
                options: this.sources,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customer && this.customer.SourceId || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.customer && this.customer.StatusId || null,
            }),
        };

        this.View = {
            Name: new DynamicLabel({
                label: 'Name',
                value: this.customer && this.customer.hasOwnProperty('Name') && this.customer.Name != null ? this.customer.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SourceId: new DynamicLabel({
                label: 'Source',
                value: getMetaItemValue(this.sources as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('SourceId') ? this.customer.SourceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StatusId: new DynamicLabel({
                label: 'Status',
                value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('StatusId') ? this.customer.StatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
