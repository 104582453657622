import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IClimateZone } from '../interfaces/climate-zone';
import { IProductCategory } from '../interfaces/product-category';

export interface IClimateZoneDynamicControlsParameters {
    formGroup?: string;
    productCategories?: IProductCategory[];
}

export class ClimateZoneDynamicControls {

    formGroup: string;
    productCategories?: IProductCategory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private climatezone?: IClimateZone, additionalParameters?: IClimateZoneDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ClimateZone';
        this.productCategories = additionalParameters && additionalParameters.productCategories || undefined;

        this.Form = {
            CondensationResistanceHigh: new DynamicField({
                formGroup: this.formGroup,
                label: 'Condensation Resistance High',
                name: 'CondensationResistanceHigh',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.CondensationResistanceHigh || null,
            }),
            CondensationResistanceLow: new DynamicField({
                formGroup: this.formGroup,
                label: 'Condensation Resistance Low',
                name: 'CondensationResistanceLow',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.CondensationResistanceLow || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.climatezone && this.climatezone.hasOwnProperty('Name') && this.climatezone.Name != null ? this.climatezone.Name.toString() : '',
            }),
            ProductCategoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Product Category',
                name: 'ProductCategoryId',
                options: this.productCategories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.climatezone && this.climatezone.ProductCategoryId || null,
            }),
            ShgcHigh: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shgc High',
                name: 'ShgcHigh',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.ShgcHigh || null,
            }),
            ShgcLow: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shgc Low',
                name: 'ShgcLow',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.ShgcLow || null,
            }),
            UFactorHigh: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Factor High',
                name: 'UFactorHigh',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.UFactorHigh || null,
            }),
            UFactorLow: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Factor Low',
                name: 'UFactorLow',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.UFactorLow || null,
            }),
            VisibleTransmittanceHigh: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Transmittance High',
                name: 'VisibleTransmittanceHigh',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.VisibleTransmittanceHigh || null,
            }),
            VisibleTransmittanceLow: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Transmittance Low',
                name: 'VisibleTransmittanceLow',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.climatezone && this.climatezone.VisibleTransmittanceLow || null,
            }),
        };

        this.View = {
            CondensationResistanceHigh: new DynamicLabel({
                label: 'Condensation Resistance High',
                value: this.climatezone && this.climatezone.CondensationResistanceHigh || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            CondensationResistanceLow: new DynamicLabel({
                label: 'Condensation Resistance Low',
                value: this.climatezone && this.climatezone.CondensationResistanceLow || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                value: this.climatezone && this.climatezone.hasOwnProperty('Name') && this.climatezone.Name != null ? this.climatezone.Name.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProductCategoryId: new DynamicLabel({
                label: 'Product Category',
                value: getMetaItemValue(this.productCategories as unknown as IMetaItem[], this.climatezone && this.climatezone.hasOwnProperty('ProductCategoryId') ? this.climatezone.ProductCategoryId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShgcHigh: new DynamicLabel({
                label: 'Shgc High',
                value: this.climatezone && this.climatezone.ShgcHigh || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            ShgcLow: new DynamicLabel({
                label: 'Shgc Low',
                value: this.climatezone && this.climatezone.ShgcLow || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UFactorHigh: new DynamicLabel({
                label: 'U Factor High',
                value: this.climatezone && this.climatezone.UFactorHigh || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UFactorLow: new DynamicLabel({
                label: 'U Factor Low',
                value: this.climatezone && this.climatezone.UFactorLow || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            VisibleTransmittanceHigh: new DynamicLabel({
                label: 'Visible Transmittance High',
                value: this.climatezone && this.climatezone.VisibleTransmittanceHigh || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            VisibleTransmittanceLow: new DynamicLabel({
                label: 'Visible Transmittance Low',
                value: this.climatezone && this.climatezone.VisibleTransmittanceLow || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
        };

    }
}
