import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IGlazingSystemSolidLayer } from '../interfaces/glazing-system-solid-layer';
import { IGlass } from '../interfaces/glass';
import { IGlazingSystem } from '../interfaces/glazing-system';
import { ISpandrelType } from '../interfaces/spandrel-type';

export interface IGlazingSystemSolidLayerDynamicControlsParameters {
    formGroup?: string;
    glazingSystems?: IGlazingSystem[];
    glasses?: IGlass[];
    spandrelTypes?: ISpandrelType[];
}

export class GlazingSystemSolidLayerDynamicControls {

    formGroup: string;
    glazingSystems?: IGlazingSystem[];
    glasses?: IGlass[];
    spandrelTypes?: ISpandrelType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private glazingsystemsolidlayer?: IGlazingSystemSolidLayer, additionalParameters?: IGlazingSystemSolidLayerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GlazingSystemSolidLayer';
        this.glazingSystems = additionalParameters && additionalParameters.glazingSystems || undefined;
        this.glasses = additionalParameters && additionalParameters.glasses || undefined;
        this.spandrelTypes = additionalParameters && additionalParameters.spandrelTypes || undefined;

        this.Form = {
            GlassId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Glass',
                name: 'GlassId',
                options: this.glasses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.GlassId || null,
            }),
            GlazingSystemId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Glazing System',
                name: 'GlazingSystemId',
                options: this.glazingSystems,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.GlazingSystemId || null,
            }),
            Layer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Layer',
                name: 'Layer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.Layer || null,
            }),
            SpandrelColor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Spandrel Color',
                name: 'SpandrelColor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(40) ],
                validators: { 'maxlength': 40 },
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.hasOwnProperty('SpandrelColor') && this.glazingsystemsolidlayer.SpandrelColor != null ? this.glazingsystemsolidlayer.SpandrelColor.toString() : '',
            }),
            SpandrelTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Spandrel Type',
                name: 'SpandrelTypeId',
                options: this.spandrelTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.SpandrelTypeId || null,
            }),
        };

        this.View = {
            GlassId: new DynamicLabel({
                label: 'Glass',
                value: getMetaItemValue(this.glasses as unknown as IMetaItem[], this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.hasOwnProperty('GlassId') ? this.glazingsystemsolidlayer.GlassId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            GlazingSystemId: new DynamicLabel({
                label: 'Glazing System',
                value: getMetaItemValue(this.glazingSystems as unknown as IMetaItem[], this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.hasOwnProperty('GlazingSystemId') ? this.glazingsystemsolidlayer.GlazingSystemId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Layer: new DynamicLabel({
                label: 'Layer',
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.Layer || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            SpandrelColor: new DynamicLabel({
                label: 'Spandrel Color',
                value: this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.hasOwnProperty('SpandrelColor') && this.glazingsystemsolidlayer.SpandrelColor != null ? this.glazingsystemsolidlayer.SpandrelColor.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SpandrelTypeId: new DynamicLabel({
                label: 'Spandrel Type',
                value: getMetaItemValue(this.spandrelTypes as unknown as IMetaItem[], this.glazingsystemsolidlayer && this.glazingsystemsolidlayer.hasOwnProperty('SpandrelTypeId') ? this.glazingsystemsolidlayer.SpandrelTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
