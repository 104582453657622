import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IGlazingSystem } from '../interfaces/glazing-system';
import { IProject } from '../interfaces/project';
import { IUser } from '../interfaces/user';

export interface IGlazingSystemDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    projects?: IProject[];
}

export class GlazingSystemDynamicControls {

    formGroup: string;
    users?: IUser[];
    projects?: IProject[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private glazingsystem?: IGlazingSystem, additionalParameters?: IGlazingSystemDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'GlazingSystem';
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.projects = additionalParameters && additionalParameters.projects || undefined;

        this.Form = {
            ComponentKey: new DynamicField({
                formGroup: this.formGroup,
                label: 'Component Key',
                name: 'ComponentKey',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(76) ],
                validators: { 'maxlength': 76 },
                value: this.glazingsystem && this.glazingsystem.hasOwnProperty('ComponentKey') && this.glazingsystem.ComponentKey != null ? this.glazingsystem.ComponentKey.toString() : '',
            }),
            Height: new DynamicField({
                formGroup: this.formGroup,
                label: 'Height',
                name: 'Height',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.Height || null,
            }),
            IsProcessed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Processed',
                name: 'IsProcessed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glazingsystem && this.glazingsystem.hasOwnProperty('IsProcessed') && this.glazingsystem.IsProcessed != null ? this.glazingsystem.IsProcessed : false,
            }),
            Layers: new DynamicField({
                formGroup: this.formGroup,
                label: 'Layers',
                name: 'Layers',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.Layers || null,
            }),
            LightToSolarGain: new DynamicField({
                formGroup: this.formGroup,
                label: 'Light To Solar Gain',
                name: 'LightToSolarGain',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.LightToSolarGain || null,
            }),
            ProjectId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Project',
                name: 'ProjectId',
                options: this.projects,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glazingsystem && this.glazingsystem.ProjectId || null,
            }),
            RelativeHeatGain: new DynamicField({
                formGroup: this.formGroup,
                label: 'Relative Heat Gain',
                name: 'RelativeHeatGain',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.RelativeHeatGain || null,
            }),
            ShadingCoefficient: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shading Coefficient',
                name: 'ShadingCoefficient',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.ShadingCoefficient || null,
            }),
            Shgc: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shgc',
                name: 'Shgc',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.Shgc || null,
            }),
            SolarReflectanceOutside: new DynamicField({
                formGroup: this.formGroup,
                label: 'Solar Reflectance Outside',
                name: 'SolarReflectanceOutside',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.SolarReflectanceOutside || null,
            }),
            SolarTransmittance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Solar Transmittance',
                name: 'SolarTransmittance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.SolarTransmittance || null,
            }),
            Thickness: new DynamicField({
                formGroup: this.formGroup,
                label: 'Thickness',
                name: 'Thickness',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.Thickness || null,
            }),
            UFactorSummer: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Factor Summer',
                name: 'UFactorSummer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.UFactorSummer || null,
            }),
            UFactorWinter: new DynamicField({
                formGroup: this.formGroup,
                label: 'U Factor Winter',
                name: 'UFactorWinter',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.UFactorWinter || null,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glazingsystem && this.glazingsystem.UserId || null,
            }),
            UserSaveDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'User Save Date',
                name: 'UserSaveDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.glazingsystem && this.glazingsystem.UserSaveDate || null,
            }),
            UvTransmittance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Uv Transmittance',
                name: 'UvTransmittance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.UvTransmittance || null,
            }),
            VisibleReflectanceInside: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Reflectance Inside',
                name: 'VisibleReflectanceInside',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.VisibleReflectanceInside || null,
            }),
            VisibleReflectanceOutside: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Reflectance Outside',
                name: 'VisibleReflectanceOutside',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.VisibleReflectanceOutside || null,
            }),
            VisibleTransmittance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Visible Transmittance',
                name: 'VisibleTransmittance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.VisibleTransmittance || null,
            }),
            Width: new DynamicField({
                formGroup: this.formGroup,
                label: 'Width',
                name: 'Width',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.glazingsystem && this.glazingsystem.Width || null,
            }),
        };

        this.View = {
            ComponentKey: new DynamicLabel({
                label: 'Component Key',
                value: this.glazingsystem && this.glazingsystem.hasOwnProperty('ComponentKey') && this.glazingsystem.ComponentKey != null ? this.glazingsystem.ComponentKey.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Height: new DynamicLabel({
                label: 'Height',
                value: this.glazingsystem && this.glazingsystem.Height || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
            IsProcessed: new DynamicLabel({
                label: 'Is Processed',
                value: this.glazingsystem && this.glazingsystem.hasOwnProperty('IsProcessed') && this.glazingsystem.IsProcessed != null ? this.glazingsystem.IsProcessed : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Layers: new DynamicLabel({
                label: 'Layers',
                value: this.glazingsystem && this.glazingsystem.Layers || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            LightToSolarGain: new DynamicLabel({
                label: 'Light To Solar Gain',
                value: this.glazingsystem && this.glazingsystem.LightToSolarGain || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            ProjectId: new DynamicLabel({
                label: 'Project',
                value: getMetaItemValue(this.projects as unknown as IMetaItem[], this.glazingsystem && this.glazingsystem.hasOwnProperty('ProjectId') ? this.glazingsystem.ProjectId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RelativeHeatGain: new DynamicLabel({
                label: 'Relative Heat Gain',
                value: this.glazingsystem && this.glazingsystem.RelativeHeatGain || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            ShadingCoefficient: new DynamicLabel({
                label: 'Shading Coefficient',
                value: this.glazingsystem && this.glazingsystem.ShadingCoefficient || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            Shgc: new DynamicLabel({
                label: 'Shgc',
                value: this.glazingsystem && this.glazingsystem.Shgc || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            SolarReflectanceOutside: new DynamicLabel({
                label: 'Solar Reflectance Outside',
                value: this.glazingsystem && this.glazingsystem.SolarReflectanceOutside || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            SolarTransmittance: new DynamicLabel({
                label: 'Solar Transmittance',
                value: this.glazingsystem && this.glazingsystem.SolarTransmittance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            Thickness: new DynamicLabel({
                label: 'Thickness',
                value: this.glazingsystem && this.glazingsystem.Thickness || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            }),
            UFactorSummer: new DynamicLabel({
                label: 'U Factor Summer',
                value: this.glazingsystem && this.glazingsystem.UFactorSummer || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UFactorWinter: new DynamicLabel({
                label: 'U Factor Winter',
                value: this.glazingsystem && this.glazingsystem.UFactorWinter || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            UserId: new DynamicLabel({
                label: 'User',
                value: getMetaItemValue(this.users as unknown as IMetaItem[], this.glazingsystem && this.glazingsystem.hasOwnProperty('UserId') ? this.glazingsystem.UserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            UserSaveDate: new DynamicLabel({
                label: 'User Save Date',
                value: this.glazingsystem && this.glazingsystem.UserSaveDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            UvTransmittance: new DynamicLabel({
                label: 'Uv Transmittance',
                value: this.glazingsystem && this.glazingsystem.UvTransmittance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            VisibleReflectanceInside: new DynamicLabel({
                label: 'Visible Reflectance Inside',
                value: this.glazingsystem && this.glazingsystem.VisibleReflectanceInside || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            VisibleReflectanceOutside: new DynamicLabel({
                label: 'Visible Reflectance Outside',
                value: this.glazingsystem && this.glazingsystem.VisibleReflectanceOutside || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            VisibleTransmittance: new DynamicLabel({
                label: 'Visible Transmittance',
                value: this.glazingsystem && this.glazingsystem.VisibleTransmittance || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            Width: new DynamicLabel({
                label: 'Width',
                value: this.glazingsystem && this.glazingsystem.Width || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            }),
        };

    }
}
