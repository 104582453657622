import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IFrameMeshNode } from '../interfaces/frame-mesh-node';
import { IFrame } from '../interfaces/frame';

export interface IFrameMeshNodeDynamicControlsParameters {
    formGroup?: string;
    frames?: IFrame[];
}

export class FrameMeshNodeDynamicControls {

    formGroup: string;
    frames?: IFrame[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private framemeshnode?: IFrameMeshNode, additionalParameters?: IFrameMeshNodeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'FrameMeshNode';
        this.frames = additionalParameters && additionalParameters.frames || undefined;

        this.Form = {
            FrameId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Frame',
                name: 'FrameId',
                options: this.frames,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.FrameId || null,
            }),
            IsEdgeNode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Edge Node',
                name: 'IsEdgeNode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.hasOwnProperty('IsEdgeNode') && this.framemeshnode.IsEdgeNode != null ? this.framemeshnode.IsEdgeNode : false,
            }),
            IsTransition: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Transition',
                name: 'IsTransition',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.hasOwnProperty('IsTransition') && this.framemeshnode.IsTransition != null ? this.framemeshnode.IsTransition : false,
            }),
            MeshNodeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mesh Node',
                name: 'MeshNodeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.MeshNodeId || null,
            }),
            Sort: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sort',
                name: 'Sort',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.Sort || null,
            }),
            Temperature: new DynamicField({
                formGroup: this.formGroup,
                label: 'Temperature',
                name: 'Temperature',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.Temperature || null,
            }),
            X: new DynamicField({
                formGroup: this.formGroup,
                label: 'X',
                name: 'X',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.X || null,
            }),
            Y: new DynamicField({
                formGroup: this.formGroup,
                label: 'Y',
                name: 'Y',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.framemeshnode && this.framemeshnode.Y || null,
            }),
        };

        this.View = {
            FrameId: new DynamicLabel({
                label: 'Frame',
                value: getMetaItemValue(this.frames as unknown as IMetaItem[], this.framemeshnode && this.framemeshnode.hasOwnProperty('FrameId') ? this.framemeshnode.FrameId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsEdgeNode: new DynamicLabel({
                label: 'Is Edge Node',
                value: this.framemeshnode && this.framemeshnode.hasOwnProperty('IsEdgeNode') && this.framemeshnode.IsEdgeNode != null ? this.framemeshnode.IsEdgeNode : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsTransition: new DynamicLabel({
                label: 'Is Transition',
                value: this.framemeshnode && this.framemeshnode.hasOwnProperty('IsTransition') && this.framemeshnode.IsTransition != null ? this.framemeshnode.IsTransition : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            MeshNodeId: new DynamicLabel({
                label: 'Mesh Node',
                value: this.framemeshnode && this.framemeshnode.MeshNodeId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            Sort: new DynamicLabel({
                label: 'Sort',
                value: this.framemeshnode && this.framemeshnode.Sort || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
            Temperature: new DynamicLabel({
                label: 'Temperature',
                value: this.framemeshnode && this.framemeshnode.Temperature || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            X: new DynamicLabel({
                label: 'X',
                value: this.framemeshnode && this.framemeshnode.X || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
            Y: new DynamicLabel({
                label: 'Y',
                value: this.framemeshnode && this.framemeshnode.Y || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 6,
                }),
            }),
        };

    }
}
